import { render, staticRenderFns } from "./modalConfigCoins_managerMarket.vue?vue&type=template&id=3c8d3d5e&scoped=true"
import script from "./modalConfigCoins_managerMarket.vue?vue&type=script&lang=js"
export * from "./modalConfigCoins_managerMarket.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c8d3d5e",
  null
  
)

export default component.exports